import { Injectable } from '@angular/core';
import { AssignmentsApiProxy, CourseAssignmentDto, LearnerAssignmentDto, MarkAssignmentRequest,
    ResetAssignmentRequest, ReturnAssignmentRequest } from '@app/core/app-services/content-services';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AssignmentsStoreService {

    assigmentMarked$: Subject<LearnerAssignmentDto> = new Subject<LearnerAssignmentDto>()

    constructor(private _assignmentApi: AssignmentsApiProxy) { }


    async markAssignment(request: MarkAssignmentRequest): Promise<LearnerAssignmentDto> {

        await this._assignmentApi.markAssignment(request).pipe(take(1)).toPromise();
        let result = await this._assignmentApi.getLearnerAssignment(request.attemptId).pipe(take(1)).toPromise();
        this.assigmentMarked$.next(result);
        return result;

    }

    async returnAssignment(request: ReturnAssignmentRequest): Promise<LearnerAssignmentDto> {

        await this._assignmentApi.returnAssignment(request).pipe(take(1)).toPromise();
        let result = await this._assignmentApi.getLearnerAssignment(request.attemptId).pipe(take(1)).toPromise();
        this.assigmentMarked$.next(result);
        return result;

    }

    async resetAssignment(attemptId: string): Promise<LearnerAssignmentDto> {

        const request = new ResetAssignmentRequest();
        request.attemptId = attemptId;


        await this._assignmentApi.resetAssignment(request).pipe(take(1)).toPromise();
        let result = await this._assignmentApi.getLearnerAssignment(request.attemptId).pipe(take(1)).toPromise();
        this.assigmentMarked$.next(result);
        return result;

    }

    async getAssignmentsForActivity(activityId: string): Promise<LearnerAssignmentDto[]> {

        let result = await this._assignmentApi.getAssignmentsForActivity(activityId).pipe(take(1)).toPromise();
        return result;
    }

    async getAssignment(activityId: string): Promise<CourseAssignmentDto> {

        let result = await this._assignmentApi.getAssignment(activityId).pipe(take(1)).toPromise();
        return result;

    }


    async getLearnerAssignment(attemptId: string): Promise<LearnerAssignmentDto> {
        let result = await this._assignmentApi.getLearnerAssignment(attemptId).pipe(take(1)).toPromise();
        return result;
    }
}
