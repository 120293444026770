import { valueAccessor } from '@syncfusion/ej2-angular-grids';

export class FilterItem<T> {

    constructor(public label:string, public item?: T ){



    }

    value(): T|undefined{
        return this.item?? undefined;
    }


    static expandItems<T extends any>(enumRef: T): FilterItem<string>[] {

        const keys = Object.keys(enumRef);

        const items = keys.map(n=> new FilterItem<string>(n, n));
        return [new FilterItem<string>('All'), ...items];


    }





    static defaultAll<T>() {
        return new FilterItem<T>("All");
    }


    static includeAll<T extends any>(items: T[], mapName: (item: T)=>string): FilterItem<T>[] {

        const filterItems: FilterItem<T>[]= [];

        filterItems.push(new FilterItem<T>("All"))

        for (let item of items) {
            filterItems.push(new FilterItem<T>(mapName(item), item))
        }
        return filterItems;



    }



}
