import { INavConfig } from './route-data';

export class TabConfig implements INavConfig {
/**
     * Path that determines what routes belong to this tabl
     */
    path: string;


    /**
     * Param key to use to discriminate routes that belong to this tab
     */
    pathKey: string;

    rootPath: string;

    url: string;
}
