import { Injectable } from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot} from '@angular/router';
import { AppRoute, AppRouteData, INavConfig } from './route-data';
import { TabConfig } from './tab-config';
import { TabNavigationService } from './tab-navigation.service';


    @Injectable({
        providedIn: 'root'
    })
export class TabRouteGuard implements CanActivate {

    constructor(
        private tabService: TabNavigationService,
        private _router: Router,

    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {




        if(!route.data['nav']) return;


        let navData = route.data['nav'] as INavConfig;
        var config = this.createTabConfig(navData, state);

        let info = this.tabService.registerTab(config);

        navData.tab = info;

       return true;
    }


    createTabConfig(settings: INavConfig, state: RouterStateSnapshot): TabConfig {
        let rootPath = state.url;
        let config = new TabConfig();
        config.path = settings.path;
        config.pathKey = settings.pathKey;

        if(settings.path) {
            rootPath = settings.path;
        }

        if(!rootPath.startsWith('/')) {
            rootPath = '/' + rootPath;
        }

        if(!rootPath.endsWith('/')) {
            rootPath = rootPath + '/';
        }

        if(settings.pathKey) {
            if(state.root.params[settings.pathKey]){
                rootPath = rootPath + state.root.params[settings.pathKey];
            }
        }

        config.rootPath = rootPath;
        config.url = state.url;


        return config;
    }
}
